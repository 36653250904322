<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <b-dropdown
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-if="false"
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/General/Search.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" style="width: 350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Search -->

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-if="false"
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" style="width: 350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-if="false"
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" style="width: 350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-if="false"
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Shopping/Cart3.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" style="width: 350px">
        <KTDropdownMyCart></KTDropdownMyCart>
      </b-dropdown-text>
    </b-dropdown>

    <!--end: My Cart -->

    <b-button
      v-allowedRoles="[Role.ADMIN]"
      size="sm"
      class="m-5 text-nowrap"
      @click="copyBearerTokenToClipboard($event.target)"
    >
      Bearer Token kopieren
    </b-button>
    <input type="hidden" :value="$store.getters.bearerToken" ref="bearerTokenInput" />
    <b-dropdown
      @click="onRefreshButtonClick"
      split
      variant="white d-flex justify-content-center align-items-center"
      class="btn btn-dropdown bg-transparent"
      toggle-class="text-primary align-items-center px-1 bg-transparent"
    >
      <template #button-content class="d-flex justify-content-center">
        <span class="text-primary align-items-center justify-content-center d-inline-flex pulse-primary">
          <b-iconstack font-scale="2" :animation="isReloadIconAnimated ? 'spin-reverse' : ''">
            <b-icon class="" stacked icon="arrow-counterclockwise"></b-icon>
            <b-icon v-if="selectedRefreshOptions === 0" stacked icon="x" scale="0.5"></b-icon>
            <b-icon v-if="selectedRefreshOptions === 1" stacked icon="hourglass-split" scale="0.4"></b-icon>
          </b-iconstack>
        </span>
      </template>
      <b-dropdown-text class="bg-gray" tag="div">
        <p class="d-flex flex-center rounded-top m-5">
          <span class="">Inhalte automatisch aktualisieren</span>
        </p>

        <b-form-radio-group
          id="btn-radios-refresh-options"
          v-model="selectedRefreshOptions"
          :options="optionsRefreshOptions"
          buttons
          stacked
          class="w-100"
        ></b-form-radio-group>
      </b-dropdown-text>
    </b-dropdown>

    <div class="topbar-item mr-1" v-allowedRoles="[Role.ADMIN]">
      <EnvironmentBanner></EnvironmentBanner>
    </div>

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTSearchDefault from '@/view/layout/extras/dropdown/SearchDefault.vue';
import KTDropdownNotification from '@/view/layout/extras/dropdown/DropdownNotification.vue';
import KTDropdownQuickAction from '@/view/layout/extras/dropdown/DropdownQuickAction.vue';
import KTDropdownMyCart from '@/view/layout/extras/dropdown/DropdownMyCart.vue';
import KTDropdownLanguage from '@/view/layout/extras/dropdown/DropdownLanguage.vue';
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';
//import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import EnvironmentBanner from '@/components/common/environment-banner.vue';

import EventBus from '@/core/common/plugins/event-bus.js';
export default {
  name: 'KTTopbar',
  data() {
    return {
      selectedRefreshOptions: 0,
      optionsRefreshOptions: [
        { text: 'Aus', value: 0 },
        { text: 'Ein', value: 1 },
      ],
      isReloadIconAnimated: false,
      isRefreshTimerFinished: false,
      refreshTimerTime: 5000,
      refreshTimerId: 0,
    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    KTDropdownMyCart,
    KTDropdownLanguage,
    KTQuickUser,
    EnvironmentBanner,
  },
  watch: {
    selectedRefreshOptions() {
      this.restartRefreshTimer();
    },
  },
  methods: {
    copyBearerTokenToClipboard(target) {
      navigator.clipboard
        .writeText('Bearer ' + this.$store.getters.accessToken)
        .then(() => {
          target.classList.remove('btn-secondary');
          target.classList.add('btn-success');
          target.innerText = 'Kopiert!';
        })
        .catch(err => {
          console.log(err);
          this.copyToClipboardFallback();
          target.classList.remove('btn-secondary');
          target.classList.add('btn-error');
          target.innerText = 'Fehler!';
        })
        .finally(() => {
          setTimeout(() => {
            target.classList.remove('btn-success');
            target.classList.remove('btn-error');
            target.classList.add('btn-secondary');
            target.innerText = 'Bearer Token kopieren';
          }, 3000);
        });
    },
    copyToClipboardFallback() {
      const el = this.$refs['bearerTokenInput'];
      el.type = 'text';
      el.select();
      document.execCommand('copy');
      el.type = 'hidden';
    },
    onRefreshButtonClick() {
      this.emitRefreshContent();
    },
    emitRefreshContent() {
      this.isReloadIconAnimated = true;
      clearTimeout(reloadAnimTimerId);
      const reloadAnimTimerId = setTimeout(() => {
        this.isReloadIconAnimated = false;
      }, 2000);
      EventBus.$emit('reload');
      this.restartRefreshTimer();
    },
    restartRefreshTimer() {
      this.isRefreshTimerFinished = false;
      clearTimeout(this.refreshTimerId);
      this.refreshTimerId = setTimeout(() => {
        this.isRefreshTimerFinished = true;
        if (this.selectedRefreshOptions === 1) {
          this.emitRefreshContent();
        }
      }, this.refreshTimerTime);
    },
  },
};
</script>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
